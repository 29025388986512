.panel{
    background-color: $white;
    padding:  0.8rem;
    border-radius: 4px;
    box-shadow: 0 3.2px 7.2px 0 $gray-700;
    display: flex;
    flex-direction: column;    
}

body {
    background-color: $gray-500;
}