.App {
    margin: 2rem;
    height: 100vh;
}


#subscriber_logo {
  max-width: 150px;
  margin-right: 1rem;
}
.App .container {
  //  height: 90%;
}
.App .panel {
   margin-top: 2rem;
   min-width: 50%;
   min-height: 20rem;
   margin-bottom: 4rem;
  // max-width: 50%;
}
.App footer {
    height: 5%;
}

@media only screen and (max-width: 600px) {
  .App {
    margin: 0.2rem;
    height: 100vh;
  }
  #subscriber_logo {
    max-width: 100px;
    margin-right: 1rem;
  }

  header {
    margin-top: 1rem;
  }
}